import { template as template_3e050f0f8b8f4bc1a8a706fe0325577e } from "@ember/template-compiler";
const FKControlMenuContainer = template_3e050f0f8b8f4bc1a8a706fe0325577e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
