import { template as template_e02bd31c90e14fde81cd2861c5eb8e78 } from "@ember/template-compiler";
const EmptyState = template_e02bd31c90e14fde81cd2861c5eb8e78(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
